import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#094663", // Primary brand color (buttons, headers, accents)
      contrastText: "#fdffff", // White text for contrast
    },
    secondary: {
      main: "#9bc7ec", // Light blue background
      contrastText: "#094663",
    },
    background: {
      default: "#fdffff", // Light gradient background
      paper: "#c0daf1", // Paper elements like cards
    },
    text: {
      primary: "#094663", // Main font color
      secondary: "#374657", // Subtext color
    },
    error: {
      main: "#d32f2f",
    },
    success: {
      main: "#388e3c",
    },
  },
  typography: {
    fontFamily: "'Titillium Web', sans-serif",
    h1: {
      fontSize: "45px",
      fontWeight: 700,
      color: "#094663",
    },
    h2: {
      fontSize: "36px",
      fontWeight: 700,
      color: "#094663",
    },
    h3: {
      fontSize: "22px",
      fontWeight: 700,
      color: "#094663",
    },
    h4: {
      fontSize: "18px",
      fontWeight: 700,
      color: "#094663",
    },
    body1: {
      fontSize: "18px",
      fontWeight: 400,
      color: "#747679",
    },
    button: {
      fontWeight: 600,
      textTransform: "none",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: "#094663",
          color: "#fdffff",
          "&:hover": {
            backgroundColor: "#06344a",
          },
        },
        outlinedPrimary: {
          border: "3px solid #094663",
          color: "#094663",
          fontWeight: 600,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "transparent",
          boxShadow: "none",
          color: "#094663",
        },
      },
    },
  },
});

export default theme;
