// cookieStorage.js
const cookieStorage = {
    setItem: function (key, value, days = 3) {
      let expires = "";
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie =
        encodeURIComponent(key) +
        "=" +
        encodeURIComponent(value) +
        expires +
        "; path=/";
    },
  
    getItem: function (key) {
      const nameEQ = encodeURIComponent(key) + "=";
      const ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") c = c.substring(1);
        if (c.indexOf(nameEQ) === 0) {
          return decodeURIComponent(c.substring(nameEQ.length));
        }
      }
      return null;
    },
  
    removeItem: function (key) {
      this.setItem(key, "", -1);
    },
  
    clear: function () {
      const cookies = document.cookie.split("; ");
      for (let cookie of cookies) {
        const eqPos = cookie.indexOf("=");
        const key = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie =
          key + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
      }
    },
  };
  
  export default cookieStorage;
  