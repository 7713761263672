import React, { useEffect, useState } from 'react';
import { useParams, Link,NavLink } from 'react-router-dom';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import { Button, List, ListItem, Alert, ListItemText, Container, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem } from '@mui/material';
import { baseAPIURL } from '../../../../store/api/Access';


import { useTeams } from '../../../../context/TeamsProvider';

const Tasks = () => {
    const { currentTeam } = useTeams();
    const teamId = currentTeam?.id; 

    const {  projectId } = useParams();
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingAdding, setLoadingAdding] = useState(false);
    const [errorAdding, setErrorAdding] = useState("");
    const [error, setError] = useState('');
    const [open, setOpen] = useState(false); // For add task dialog
    const [filter, setFilter] = useState('all'); // Task filter

    const [taskName, setTaskName] = useState('');
    
    //const [filter, setFilter] = useState('all');



    const fetchTasks = async (filter = 'all') => {
        setLoading(true);
        try {
            const idToken = await firebase.auth().currentUser.getIdToken();
            let url = `${baseAPIURL}/projects/${projectId}/tasks`;
            if (filter !== 'all') {
                url += `?status=${filter}`;
            }
            const response = await axios.get(url, {
                headers: { 'Authorization': idToken },
            });
            setTasks(response.data);
        } catch (err) {
            console.error('Error fetching tasks:', err);
            setError('Failed to load tasks');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTasks(filter);
    }, [teamId, projectId, filter]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleFilterChange = (event) => {
        setFilter(event.target.value);
        fetchTasks(event.target.value);
    };

   
    const handleAddTask = async () => {
        setLoadingAdding(true);
        try {
            const idToken = await firebase.auth().currentUser.getIdToken(); // Get Firebase ID token
            const response = await axios.post(`${baseAPIURL}/projects/${projectId}/tasks`, {
                name: taskName,
                // Add other fields as needed
            }, {
                headers: { 'Authorization': idToken },
            });
            
            
            if(response.status != 200)
            {
                setTasks([...tasks, {name:taskName}]); // Assuming the API returns the created task
                setLoadingAdding(false)
                setTaskName("");
                handleClose();
            }
            else
            {
                setErrorAdding("cannot Add");
                setLoadingAdding(false)
            }
        } catch (err) {
            console.error('Error adding new task:', err);
            setError('Failed to add new task.');
        } finally {
            setLoadingAdding(false);
        }
    };

    return (
        <Container>
            <Button variant="contained" color="primary" onClick={handleOpen}>
                Add Task
            </Button>
            <Select
                value={filter}
                onChange={handleFilterChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
            >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="in_progress">In Progress</MenuItem>
                <MenuItem value="completed">Completed</MenuItem>
            </Select>
            {loading ? <p>Loading...</p> : error ? <p>{error}</p> : (
                <List>
                    {tasks.map((task) => (
                        <ListItem 
                        component={NavLink}
                        to={`/projects/${projectId}/tasks/${task.task_id}`}
                        key={task.id}>
                            <ListItemText primary={task.name} secondary={task.status} />
                        </ListItem>
                    ))}
                </List>
            )}
            <Dialog open={open} onClose={!loadingAdding ? handleClose : null}>
                <DialogTitle>Add New Task</DialogTitle>
                <DialogContent>

                {errorAdding != "" ? <Alert severity="error">{errorAdding}</Alert> : null}

                {loadingAdding ? <p>Loading...</p> :
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Task Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={taskName}
                        onChange={(e) => {setTaskName(e.target.value);setErrorAdding("");}}
                    />
                }

                </DialogContent>
                
                {!loadingAdding ? 
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleAddTask}>Add</Button>
                </DialogActions>
                : null }
            </Dialog>
        </Container>
    );
};

export default Tasks;
