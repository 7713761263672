import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Paper, Button, IconButton, Typography, CircularProgress, Alert, List, ListItem, ListItemText, TextField, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

import firebase from 'firebase/compat/app';
import axios from 'axios';
import { baseAPIURL } from '../../store/api/Access';
import { useTeams } from '../../context/TeamsProvider';

export default function TeamDocumentsItem() {
  const { currentTeam } = useTeams();
  const teamId = currentTeam?.id;
  const { documentId } = useParams();
  const navigate = useNavigate();

  const [docs, setDocs] = useState([]);
  const [currentDoc, setCurrentDoc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [expandedDocs, setExpandedDocs] = useState({}); // Stores expanded state for each document

  const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [changeParentOpen, setChangeParentOpen] = useState(false);
  const [selectedParent, setSelectedParent] = useState(null);



  const closeSidebarMobile = () => {
    if (window.innerWidth <= 768) {
      setSidebarOpen(false);
    }
  };


  const fetchDocs = async () => {
    setLoading(true);
    try {
      const token = await firebase.auth().currentUser.getIdToken();
      const res = await axios.get(`${baseAPIURL}/teams/${teamId}/documents`, {
        headers: { Authorization: token },
      });
      setDocs(res.data || []);
    } catch (err) {
      setError('Failed to fetch documents');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (teamId) fetchDocs();
  }, [teamId]);

  useEffect(() => {
    if (docs.length > 0) {
      const selected = docs.find(d => d.doc_id.toString() === documentId) ||
                       docs.find(d => d.parent_doc_id === 0 || d.parent_doc_id === null) || 
                       docs[0];
      setCurrentDoc(selected);
      console.log(selected)
      setContent(selected?.content || '');
      setTitle(selected?.title || '');
      setSelectedParent(selected?.parent_doc_id || 0);
    }
  }, [documentId, docs]);

  const saveDoc = async () => {
    setLoading(true);
    try {
      const token = await firebase.auth().currentUser.getIdToken();

      

      var data = { title:title, content:content, parentDocId:selectedParent }


     

      await axios.put(`${baseAPIURL}/documents/${currentDoc.doc_id}`,
        data,
        { headers: { Authorization: token } }
      );
      fetchDocs();
      setEditMode(false);
    } catch (err) {
      setError('Failed to save document');
    }
    setLoading(false);
  };


  const  startEditMode = () =>{ 
    setSelectedParent(null)
    setEditMode(true)
  }

  const createDoc = async (parent_doc_id = null) => {
    setLoading(true);
    try {
       const documentName  = parent_doc_id == 0 ? currentTeam.name :'New Document';
      const token = await firebase.auth().currentUser.getIdToken();
      const res = await axios.post(`${baseAPIURL}/teams/${teamId}/documents`,
        { title:documentName , content: '', parentDocId: parent_doc_id },
        { headers: { Authorization: token } }
      );
      fetchDocs();
      navigate(`/documents/${res.data.doc_id}`);
      setEditMode(true);
    } catch (err) {
      setError('Failed to create document');
    }
    setLoading(false);
  };

  const deleteDoc = async () => {
    setLoading(true);
    try {
      const token = await firebase.auth().currentUser.getIdToken();
      await axios.delete(`${baseAPIURL}/documents/${currentDoc.doc_id}`, {
        headers: { Authorization: token }
      });
      fetchDocs();
      setEditMode(false);
      navigate('/documents');
    } catch (err) {
      setError('Failed to delete document');
    }
    setConfirmDelete(false);
    setLoading(false);
  };

  /*
  const changeParent = async () => {
    setLoading(true);
    try {
      const token = await firebase.auth().currentUser.getIdToken();
      await axios.put(`${baseAPIURL}/documents/${currentDoc.doc_id}`,
        { parentDocId: selectedParent },
        { headers: { Authorization: token } }
      );
      fetchDocs();
      setChangeParentOpen(false);
    } catch (err) {
      setError('Failed to change parent');
    }
    setLoading(false);
  };*/

 
const renderHierarchicalDocs = () => {
  const validDocIds = new Set(docs.map(doc => doc.doc_id));
  const hierarchicalDocs = docs.filter(doc => doc.parent_doc_id === null || doc.parent_doc_id === 0 || validDocIds.has(doc.parent_doc_id));
  const orphanDocs = docs.filter(doc => doc.parent_doc_id && !validDocIds.has(doc.parent_doc_id));



  
const toggleExpand = (docId) => {
  setExpandedDocs(prev => ({
    ...prev,
    [docId]: !prev[docId] // Toggle state per document
  }));
};

const renderDocs = (parentId = null, level = 0) => {
  const children = hierarchicalDocs.filter(doc => parentId === null ? (doc.parent_doc_id === null || doc.parent_doc_id === 0) : doc.parent_doc_id === parentId);

  return children.map(doc => {
    const hasChildren = hierarchicalDocs.some(child => child.parent_doc_id === doc.doc_id);
    const isSelected = currentDoc && currentDoc.doc_id === doc.doc_id;

    return (
      <Box key={doc.doc_id} sx={{ pl: level * 2 }}>
        <ListItem button selected={isSelected} onClick={() => {closeSidebarMobile();;navigate(`/documents/${doc.doc_id}`)}}>
          {hasChildren && (
            <IconButton size="small" onClick={(e) => { e.stopPropagation(); toggleExpand(doc.doc_id); }}>
              <ExpandMoreIcon sx={{ transform: expandedDocs[doc.doc_id] ? 'rotate(180deg)' : 'rotate(0deg)' }} />
            </IconButton>
          )}
          <ListItemText 
            primary={
              <Typography fontWeight={isSelected ? 'bold' : 'normal'}>
                {doc.title}
              </Typography>
            } 
          />
          <IconButton size="small" onClick={(e) => { e.stopPropagation(); closeSidebarMobile(); createDoc(doc.doc_id); }}>
            <AddIcon fontSize="small" />
          </IconButton>
        </ListItem>

        {hasChildren && (
          <Collapse in={expandedDocs[doc.doc_id]} timeout="auto" unmountOnExit>
            {renderDocs(doc.doc_id, level + 1)}
          </Collapse>
        )}
      </Box>
    );
  });
};








  return (
    <>
      {renderDocs()}
      
      {orphanDocs.length > 0 && (
        <>
          <Typography variant="subtitle1" fontWeight="bold" sx={{ mt: 2 }}>Orphan Documents</Typography>
          {orphanDocs.map(doc => {
            const isSelected = currentDoc && currentDoc.doc_id === doc.doc_id;
            const hasChildren = hierarchicalDocs.some(child => child.parent_doc_id === doc.doc_id);

            return (
              <Box key={doc.doc_id} sx={{ pl: 2 }}>
                {hasChildren ? (
                  <Accordion defaultExpanded={isSelected}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography fontWeight={isSelected ? 'bold' : 'normal'} onClick={(e) => { e.stopPropagation(); navigate(`/documents/${doc.doc_id}`); }}>
                        {doc.title}
                      </Typography>
                      <IconButton size="small" onClick={(e) => { e.stopPropagation(); createDoc(doc.doc_id); }}>
                        <AddIcon fontSize="small" />
                      </IconButton>
                    </AccordionSummary>
                    <AccordionDetails>
                      {renderDocs(doc.doc_id, 2)}
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <ListItem button selected={isSelected} onClick={() => navigate(`/documents/${doc.doc_id}`)}>
                    <ListItemText primary={<Typography fontWeight={isSelected ? 'bold' : 'normal'}>{doc.title}</Typography>} />
                    <IconButton size="small" onClick={(e) => { e.stopPropagation(); createDoc(doc.doc_id); }}>
                      <AddIcon fontSize="small" />
                    </IconButton>
                  </ListItem>
                )}
              </Box>
            );
          })}
        </>
      )}
    </>
  );
};


  if (loading && !currentDoc) return <CircularProgress />;

  return (
    <Box sx={{ p: 2, display: 'flex' }}>
      {error && <Alert severity="error">{error}</Alert>}

      <Box sx={{ flex: 1 }}>
        {docs.length === 0 ? (
          <Box>
            <Typography>No documents available.</Typography>
            <Button onClick={() => createDoc(0)} variant="contained">Create Document</Button>
          </Box>
        ) : (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              {editMode ? (
                <>
                  <TextField value={title=="New Document"?"":title} sx={{flexGrow:1}} label={title=="New Document"?title:title==""?"Title":""} variant="standard" onChange={(e) => setTitle(e.target.value)}  />
                  <Box>
                    <IconButton onClick={(e) => setSettingsAnchorEl(e.currentTarget)}><SettingsIcon/></IconButton>
                    <Menu anchorEl={settingsAnchorEl} open={Boolean(settingsAnchorEl)} onClose={() => setSettingsAnchorEl(null)}>
                      <MenuItem onClick={() => {setConfirmDelete(true);setSettingsAnchorEl(null)}}>Delete Document</MenuItem>
                      <MenuItem onClick={() => {setChangeParentOpen(true);;setSettingsAnchorEl(null)}}>Change Parent</MenuItem>
                    </Menu>
                    <Button variant="contained" onClick={saveDoc}>Save</Button>
                  </Box>
                </>
              ) : (
                <>
                  <IconButton onClick={() => setSidebarOpen(!sidebarOpen)}><MenuIcon /></IconButton>
                  <Typography variant="h5">{currentDoc?.title || 'Untitled'}</Typography>
                  <Button variant="outlined" onClick={() => startEditMode()}>Edit</Button>
                </>
              )}
            </Box>

            <Box sx={{ display: 'flex', mt: 2, flexDirection: { xs: 'column', md: 'row' } }}>
              
                <Collapse in={sidebarOpen && !editMode } timeout="auto" unmountOnExit>

                <Box sx={{ width: { xs: '100%', md: 250 }, mb: { xs: 2, md: 0 }, borderRight: { md: '1px solid #ccc' }, borderBottom: { xs: '1px solid #ccc', md: 'none' } }}>
                  <Typography variant="h6">Pages</Typography>
                  <List>{renderHierarchicalDocs()}</List>
                </Box>
                </Collapse>
              

              <Paper sx={{ p: 2, flex: 1 }}>
                {editMode ? (
                  <>
                    <ReactQuill theme="snow" value={content} onChange={setContent} />
                    <Button sx={{ mt: 2 }} variant="contained" onClick={saveDoc}>Save</Button>
                    <Button sx={{ mt: 2, ml: 1 }} onClick={() => setEditMode(false)}>Cancel</Button>
                  </>
                ) : (
                  <Box dangerouslySetInnerHTML={{ __html: (currentDoc?.content ) || 'Blank document.' }} />
                )}
              </Paper>
            </Box>
          </>
        )}

        {loading && <CircularProgress sx={{ mt: 2 }} />}
      </Box>

      {/* Confirm Delete Dialog */}
      <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>Are you sure you want to delete this document?</DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(false)}>Cancel</Button>
          <Button onClick={deleteDoc} color="error">Delete</Button>
        </DialogActions>
      </Dialog>

      {/* Change Parent Dialog */}
      <Dialog open={changeParentOpen} onClose={() => setChangeParentOpen(false)}>
        <DialogTitle>Select New Parent</DialogTitle>
        <DialogContent>
          <List>
            {docs.map(doc =>(<>{ 
                currentDoc?.doc_id != doc.doc_id ? (
              <ListItem button key={doc.doc_id} onClick={() => {setSelectedParent(doc.doc_id);setChangeParentOpen(false)}}>
                <ListItemText primary={doc?.title || 'Untitled'}  />
              </ListItem>
            )
            :
            null
        
            }</>))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setChangeParentOpen(false)}>Cancel</Button>
          {/*<Button onClick={changeParent} variant="contained">Save</Button>*/}
        </DialogActions>
      </Dialog>
    </Box>
  );
}
