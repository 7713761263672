import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Container, Typography, Button, Table, TableBody, TableCell, TableHead, TableRow, 
  TextField, Select, MenuItem, FormControl, InputLabel, Box 
} from '@mui/material';
import axios from 'axios';

const Inventory = () => {
  const [inventory, setInventory] = useState([]);
  const [search, setSearch] = useState('');
  const [filterType, setFilterType] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    axios.get('/api/inventory')
      .then((response) => setInventory(response.data))
      .catch((error) => console.error('Error fetching inventory:', error));

    axios.get('/api/inventory/categories')
      .then((response) => setCategories(response.data))
      .catch((error) => console.error('Error fetching categories:', error));

    axios.get('/api/inventory/locations')
      .then((response) => setLocations(response.data))
      .catch((error) => console.error('Error fetching locations:', error));
  }, []);

  // Filter inventory based on search, type, and status
  const filteredInventory = inventory.filter(item =>
    item.name.toLowerCase().includes(search.toLowerCase()) &&
    (filterType ? item.type === filterType : true) &&
    (filterStatus ? item.status === filterStatus : true)
  );

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Inventory Management</Typography>
      
      {/* Top Controls: Add New, View Categories, View Locations */}
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Button variant="contained" color="primary" component={Link} to="/inventory/new">
          Add New Item
        </Button>
        <Box>
          <Button variant="outlined" component={Link} to="/inventory/categories">Manage Categories</Button>
          <Button variant="outlined" component={Link} to="/inventory/locations" sx={{ ml: 1 }}>Manage Locations</Button>
        </Box>
      </Box>

      {/* Search & Filters */}
      <Box display="flex" gap={2} mb={2}>
        <TextField
          label="Search by Name"
          variant="outlined"
          fullWidth
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel>Type</InputLabel>
          <Select value={filterType} onChange={(e) => setFilterType(e.target.value)}>
            <MenuItem value="">All Types</MenuItem>
            <MenuItem value="part">Part</MenuItem>
            <MenuItem value="equipment">Equipment</MenuItem>
            <MenuItem value="product">Product</MenuItem>
            <MenuItem value="software">Software</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel>Status</InputLabel>
          <Select value={filterStatus} onChange={(e) => setFilterStatus(e.target.value)}>
            <MenuItem value="">All Status</MenuItem>
            <MenuItem value="in_stock">In Stock</MenuItem>
            <MenuItem value="low_stock">Low Stock</MenuItem>
            <MenuItem value="out_of_stock">Out of Stock</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Inventory Table */}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Stock</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredInventory.map((item) => (
            <TableRow key={item.inventory_id}>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.type}</TableCell>
              <TableCell>{item.category || 'N/A'}</TableCell>
              <TableCell>{item.location || 'N/A'}</TableCell>
              <TableCell 
                style={{ color: item.status === 'out_of_stock' ? 'red' : item.status === 'low_stock' ? 'orange' : 'green' }}
              >
                {item.status.replace('_', ' ')}
              </TableCell>
              <TableCell>{item.stock_level}</TableCell>
              <TableCell>
                <Button component={Link} to={`/inventory/${item.inventory_id}`} color="primary">View</Button>
                <Button component={Link} to={`/inventory/${item.inventory_id}/edit`} color="secondary" sx={{ ml: 1 }}>Edit</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

export default Inventory;
