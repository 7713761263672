import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import { Container,Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@mui/material';
import { baseAPIURL } from '../../../store/api/Access';


import { useTeams } from '../../../context/TeamsProvider';

const OtherTeamSettings = () => {
    const { currentTeam } = useTeams();
    const teamId = currentTeam?.id; 

    //const { teamId } = useParams();
    const navigate = useNavigate();
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleOpenConfirmDialog = () => setOpenConfirmDialog(true);
    const handleCloseConfirmDialog = () => setOpenConfirmDialog(false);

    const handleDeleteTeam = async () => {
        setLoading(true); // Start loading
        try {
            const idToken = await firebase.auth().currentUser.getIdToken();
            await axios.delete(`${baseAPIURL}/teams/${teamId}`, {
                headers: { 'Authorization': idToken },
            });
            navigate('/'); // Navigate away after successful deletion
        } catch (err) {
            console.error('Error deleting team:', err);
            // Optionally handle deletion error (e.g., show an error message)
        } finally {
            setLoading(false); // Stop loading regardless of outcome
        }
    };

    return (
        <Container>
            <Button variant="outlined" color="error" onClick={handleOpenConfirmDialog}>
                Delete Team
            </Button>

            {/* Confirmation Dialog */}
            <Dialog
                open={openConfirmDialog}
                onClose={handleCloseConfirmDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm Team Deletion"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this team? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmDialog}>Cancel</Button>
                    <Button onClick={handleDeleteTeam} autoFocus color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Loading Popup Dialog */}
            <Dialog open={loading} sx={{p:2}}>
                <Box sx={{m:2}}>
                <CircularProgress />
                </Box>
            </Dialog>
        </Container>
    );
};

export default OtherTeamSettings;
