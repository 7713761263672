import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import Loader from '../../components/other/Loader';
import { baseAPIURL } from '../../store/api/Access';
import { TextField, Dialog, Button, Typography, Container, Paper, Box, Alert } from '@mui/material';


import { useTeams } from '../../context/TeamsProvider';

const NewProject = () => {
    const { currentTeam } = useTeams();
    const teamId = currentTeam?.id; 

    //const { teamId } = useParams();
    const navigate = useNavigate();
    const [projectName, setProjectName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const validate = () => {
        let tempErrors = {};
        tempErrors.projectName = projectName ? "" : "Project name is required.";
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            setLoading(true);
            setError('');

            try {
                firebase.auth().currentUser.getIdToken().then(function(idToken) {
                    let config = {
                        method: 'post',
                        url: `${baseAPIURL}/teams/${teamId}/projects`,
                        headers: { 
                            'Authorization': idToken, 
                            'Content-Type': 'application/json'
                        },
                        data: JSON.stringify({
                            name: projectName,
                            description: projectDescription
                        })
                    };

                    axios.request(config)
                    .then(response => {
                        if(response.data,response.data)
                        navigate(`/projects/${teamId}`);
                    })
                    .catch(err => {
                        console.error('Error creating project:', err);
                        setError('Failed to create project. Please try again.');
                    })
                    .finally(() => {
                        setLoading(false);
                    });
                });
            } catch (err) {
                console.error('Error creating project:', err);
                setError('Failed to create project. Please try again.');
                setLoading(false);
            }
        }
    };

    return (
        <Container maxWidth="sm">
            <Paper style={{ padding: 16 }}>
                <Typography variant="h6" gutterBottom>
                    Create New Project
                </Typography>
                {error && <Alert severity="error" style={{ marginBottom: 16 }}>{error}</Alert>}
                <form onSubmit={handleSubmit}>
                    <Box marginY={2}>
                        <TextField
                            fullWidth
                            label="Project Name"
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}
                            required
                            error={!!errors.projectName}
                            helperText={errors.projectName}
                        />
                    </Box>
                    <Box marginY={2}>
                        <TextField
                            fullWidth
                            label="Project Description"
                            value={projectDescription}
                            onChange={(e) => setProjectDescription(e.target.value)}
                            multiline
                            rows={4}
                        />
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={loading}
                        style={{ position: 'relative' }}
                    >
                        Create Project
                    </Button>
                </form>
            </Paper>
            <Dialog open={loading} sx={{"& .MuiPaper-root": {background: "transparent", boxShadow: "none"}}}>
                <Loader />
            </Dialog>
        </Container>
    );
};

export default NewProject;
