import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import Loader from '../../components/other/Loader';
import { baseAPIURL } from '../../store/api/Access';
import { Container, Typography, Paper, List, ListItem, ListItemText, Button, Box, Alert } from '@mui/material';
import { NavLink  } from 'react-router-dom'
import { useTeams } from '../../context/TeamsProvider';

const TeamSuppliers = () => {
    const { currentTeam } = useTeams();
    const teamId = currentTeam?.id; 
    //const { teamId } = useParams();
    const navigate = useNavigate();
    const [suppliers, setSuppliers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        setLoading(true);

        firebase.auth().currentUser.getIdToken().then(function(idToken) {
            let config = {
                method: 'get',
                url: `${baseAPIURL}/teams/${teamId}/suppliers`,
                headers: { 'Authorization': idToken }
            };

            axios.request(config)
                .then(response => {
                    setSuppliers(response.data);
                    setLoading(false);
                })
                .catch(err => {
                    console.error('Error fetching suppliers:', err);
                    setError('Failed to load suppliers');
                    setLoading(false);
                });
        });
    }, [teamId]);

    const handleAddSuppliers = () => {
        navigate(`/new-suppliers`);
    };

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    return (
        <Container maxWidth="md">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h4">Team Suppliers</Typography>
                <Button variant="contained" color="primary" onClick={handleAddSuppliers}>
                    Add Suppliers
                </Button>
            </Box>
            <Paper style={{ padding: 16 }}>
                {suppliers.length > 0 ? (
                    <List>
                        {suppliers.map(suppliers => (
                            <ListItem component={NavLink} to={"/team/2/suppliers/"+suppliers.supplier_id} key={suppliers.supplier_id}>
                                <ListItemText primary={suppliers.name} secondary={suppliers.contactInfo} />
                                {/* Additional details or links */}
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <Typography variant="body1">No suppliers found for this team.</Typography>
                )}
            </Paper>
        </Container>
    );
};

export default TeamSuppliers;
