import React from 'react';
import { NavLink } from 'react-router-dom';

import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import DashboardIcon from '@mui/icons-material/Dashboard';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import DescriptionIcon from '@mui/icons-material/Description';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import PeopleIcon from '@mui/icons-material/People';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const drawerWidth = 240;

const Sidebar = ({
  open,
  isWideView,
  teamId,
  pathname,
  handleDrawerClose,
}) => {
  // Styles for highlighting the current/active menu item
  const highlightClass = {
    fontWeight: '700 !important',
    '& *': {
      fontWeight: '700 !important',
    },
  };

  const shouldHighlightMenuItem = (menuItemPath) => {
    return pathname === menuItemPath;
  };

  const shouldHighlightMenuItemWildCard = (menuItemPath) => {
    return pathname.startsWith(menuItemPath);
  };

  // Define all sidebar links in one place:
  const linkItems = [
    {
      label: 'Overview',
      icon: <DashboardIcon />,
      path: `/`,
      useWildcard: false,
    },
    {
      label: 'Projects',
      icon: <WorkOutlineIcon />,
      path: `/projects`,
      useWildcard: true,
    },
    {
      label: 'Documents',
      icon: <DescriptionIcon />,
      path: `/documents`,
      useWildcard: true,
    },
    {
      label: 'Inventory',
      icon: <InventoryIcon />,
      path: `/inventory`,
      useWildcard: true,
    },
    /*{
      label: 'Products',
      icon: <ShoppingBagIcon />,
      path: `/products`,
      useWildcard: true,
    },
    /*{
      label: 'Suppliers',
      icon: <LocalShippingIcon />,
      path: `/suppliers`,
      useWildcard: true,
    },
    /*{
      label: 'Customers',
      icon: <PeopleIcon />,
      path: `/customers`,
      useWildcard: true,
    },*/
    /*{
      label: 'Attachments',
      icon: <AttachFileIcon />,
      path: `/attachments`,
      useWildcard: true,
    },
    {
      label: 'Accounting',
      icon: <AccountBalanceIcon />,
      path: `/accounting`,
      useWildcard: true,
    },*/
  ];

  // Render the Drawer
  return (
    <Drawer
      anchor="left"
      open={open}
      variant={!isWideView ? 'permanent' : 'persistent'}
      sx={{
        marginRight: isWideView ? '-200px' : 0,
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          background: 'transparent',
          borderRight: 'none',
        },
      }}
    >
      <Toolbar />
      <Box
        sx={{
          overflow: 'auto',
          background: !isWideView ? 'transparent' : '#fff',
          borderRight: !isWideView
            ? '1px solid #094663 !important'
            : 'none',
        }}
      >
        <List>
          {linkItems.map(({ label, icon, path, useWildcard }) => {
            // Determine highlight condition
            const highlighted = useWildcard
              ? shouldHighlightMenuItemWildCard(path)
              : shouldHighlightMenuItem(path);

            return (
              <ListItem
                key={label}
                sx={highlighted ? highlightClass : {}}
                onClick={handleDrawerClose}
                component={NavLink}
                to={path}
                disablePadding
              >
                <ListItemButton className="TeamMenuListItem">
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={label} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
