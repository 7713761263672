import React, { useEffect, useState } from 'react';
import { useParams, useNavigate,useLocation } from 'react-router-dom';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import Loader from '../../components/other/Loader';
import { baseAPIURL } from '../../store/api/Access';
import { Container, Typography, Paper, Tabs, Tab, Box, CircularProgress, Alert } from '@mui/material';
import { BrowserRouter,Outlet, Route, Routes  } from 'react-router-dom'
import { NavLink  } from 'react-router-dom'


import { useTeams } from '../../context/TeamsProvider';

const ProjectDetails = ({children}) => {
    const { currentTeam } = useTeams();
    const teamId = currentTeam?.id; 

    //const { teamId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname.split('/')
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [tabValue, setTabValue] = useState(path.length>4 ? path[4] : "");

/*
    useEffect(() => {
        /*setLoading(true);

        firebase.auth().currentUser.getIdToken().then(function(idToken) {
            let config = {
                method: 'get',
                url: `${baseAPIURL}/projects/${projectId}`,
                headers: { 'Authorization': idToken }
            };

            axios.request(config)
                .then(response => {
                    setProject(response.data);
                    setLoading(false);
                })
                .catch(err => {
                    console.error('Error fetching project details:', err);
                    setError('Failed to load project details');
                    setLoading(false);
                });
        });
    }, [teamId, projectId]);
*/




    
    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
        
        navigate(`/settings/${newValue}`);
    };



/*
    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }*/

    return (
        <Container maxWidth="md">
            <Paper style={{ padding: 16, marginTop: 16 }}>
                {/*<Typography variant="h4">{project?.name}</Typography>
                <Typography variant="body1" style={{ marginTop: 8 }}>
                    {project?.description}
                </Typography>*/}
                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: 3 }}>
                    <Tabs 
                        value={tabValue} 
                        onChange={handleChangeTab} 
                        aria-label="Project Tabs"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label="Overview"   value="" />
                        <Tab label="Team"  value="team" />
                        <Tab label="Other"  value="other" />
                    </Tabs>
                </Box>

                    <Outlet />
            </Paper>
        </Container>
    );
};

export default ProjectDetails;
