import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import Loader from '../../components/other/Loader';
import { baseAPIURL } from '../../store/api/Access';
import { Container, Typography, Paper, Alert } from '@mui/material';



import { useTeams } from '../../context/TeamsProvider';

const CustomerDetails = () => {
    const { currentTeam } = useTeams();
    const teamId = currentTeam?.id; 

    const {/* teamId,*/ customerId } = useParams();
    const [customer, setCustomer] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        setLoading(true);

        firebase.auth().currentUser.getIdToken().then(function(idToken) {
            let config = {
                method: 'get',
                url: `${baseAPIURL}/customers/${customerId}`,
                headers: { 'Authorization': idToken }
            };

            axios.request(config)
                .then(response => {
                    setCustomer(response.data);
                    setLoading(false);
                })
                .catch(err => {
                    console.error('Error fetching customer details:', err);
                    setError('Failed to load customer details');
                    setLoading(false);
                });
        });
    }, [teamId, customerId]);

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    return (
        <Container maxWidth="md">
            <Paper style={{ padding: 16, marginTop: 16 }}>
                {customer ? (
                    <>
                        <Typography variant="h4">{customer.name}</Typography>
                        <Typography variant="body1" style={{ marginTop: 8 }}>
                            Contact Info: {customer.contactInfo}
                        </Typography>
                        {/* Add additional customer details here */}
                    </>
                ) : (
                    <Typography variant="body1">Customer not found.</Typography>
                )}
            </Paper>
        </Container>
    );
};

export default CustomerDetails;
