import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import Loader from '../components/other/Loader';
import Page404 from './Page404';

import { baseAPIURL } from '../store/api/Access';
import {
  Button,
  Box,
  Typography,
  Alert,
  Paper,
  List,
  ListItem,
  ListItemText
} from '@mui/material';

import { useTeams } from '../context/TeamsProvider';

const TeamPage = () => {
  // 1) Grab the currentTeam from the provider
  const { currentTeam } = useTeams();
  // The ID for the team
  const teamId = currentTeam?.id;

  // State for team details
  const [team, setTeam] = useState(null);

  // State for extra data
  const [members, setMembers] = useState([]);
  const [activities, setActivities] = useState([]);
  const [projects, setProjects] = useState([]);

  // State for loading/errors
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Fetch data
  useEffect(() => {
    if (!teamId) return;

    setLoading(true);

    firebase.auth().currentUser
      .getIdToken()
      .then(idToken => {
        const config = {
          headers: { Authorization: idToken },
        };

        // Fetch all data in parallel (team, members, activities, projects)
        return Promise.all([
          axios.get(`${baseAPIURL}/teams/${teamId}`, config),           // get main team info
          axios.get(`${baseAPIURL}/teams/${teamId}/members`, config),   // get team members
          axios.get(`${baseAPIURL}/teams/${teamId}/activities`, config),// get recent activities
          axios.get(`${baseAPIURL}/teams/${teamId}/projects`, config),  // get team projects
        ]);
      })
      .then(([teamRes, membersRes, activitiesRes, projectsRes]) => {
        setTeam(teamRes.data);
        setMembers(membersRes.data);
        setActivities(activitiesRes.data);   // Each item has { id, date, team_id, key, record_id, status, description }
        setProjects(projectsRes.data);
        setLoading(false);
      })
      .catch(err => {
        console.error('Error fetching team data:', err);
        setError('Failed to load team data');
        setLoading(false);
      });
  }, [teamId]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  if (!team) {
    return <Page404 />;
  }

  // RENDER FUNCTIONS

  // 1) Team members
  const renderTeamMembers = () => {
    if (!members.length) {
      return <Typography>No members found.</Typography>;
    }
    return (
      <List>
        {members.map(member => (
          <ListItem key={member.user_id || member.id}>
            <ListItemText primary={`${member.name} - ${member.role || ''}`} />
          </ListItem>
        ))}
      </List>
    );
  };

  // 2) Recent activities
  // Use the fields from the activity object you provided:
  // {
  //   "id": 2,
  //   "date": "2025-03-17T21:19:24.000Z",
  //   "team_id": 2,
  //   "key": "docs",
  //   "record_id": 20,
  //   "status": "created",
  //   "description": "New doc created: test"
  // }
  const renderRecentActivities = () => {
    if (!activities.length) {
      return <Typography>No recent activities found.</Typography>;
    }

    return (
      <List>
        {activities.map(activity => {
          // Format the timestamp if desired
          const formattedDate = new Date(activity.date).toLocaleString();
          return (
            <ListItem key={activity.id}>
              <ListItemText
                primary={`${activity.status}`}
                secondary={`${activity.description} — ${formattedDate}`}
              />
            </ListItem>
          );
        })}
      </List>
    );
  };

  // 3) Projects
  const renderProjects = () => {
    if (!projects.length) {
      return <Typography>No projects found.</Typography>;
    }
    return (
      <List>
        {projects.map(project => (
          <ListItem
            key={project.project_id}
            button
            component={Link}
            to={`/projects/${project.project_id}`}
          >
            <ListItemText
              primary={project.name}
              secondary={`Status: ${project.status || 'N/A'}`}
            />
          </ListItem>
        ))}
      </List>
    );
  };

  // MAIN RENDER
  return (
    <Paper style={{ padding: 16, margin: 16 }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h4" component="span">
          {team.name}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={`/settings`}
        >
          Settings
        </Button>
      </Box>

      <Typography variant="body1" style={{ marginTop: 16 }}>
        {team.description}
      </Typography>

      <Paper elevation={3} style={{ margin: '16px 0', padding: '16px' }}>
        <Typography variant="h6">Team Members</Typography>
        {renderTeamMembers()}
      </Paper>

      <Paper elevation={3} style={{ margin: '16px 0', padding: '16px' }}>
        <Typography variant="h6">Recent Activities</Typography>
        {renderRecentActivities()}
      </Paper>

      <Paper elevation={3} style={{ margin: '16px 0', padding: '16px' }}>
        <Typography variant="h6">Projects</Typography>
        {renderProjects()}
      </Paper>
    </Paper>
  );
};

export default TeamPage;
