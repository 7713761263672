import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import Loader from '../../components/other/Loader';
import { baseAPIURL } from '../../store/api/Access';
import { Container, Typography, Paper, Alert } from '@mui/material';




import { useTeams } from '../../context/TeamsProvider';


const ProductsDetails = () => {
    const { currentTeam } = useTeams();
    const teamId = currentTeam?.id; 


    const {  productsId } = useParams();
    const [products, setProducts] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        setLoading(true);

        firebase.auth().currentUser.getIdToken().then(function(idToken) {
            let config = {
                method: 'get',
                url: `${baseAPIURL}/products/${productsId}`,
                headers: { 'Authorization': idToken }
            };

            axios.request(config)
                .then(response => {
                    setProducts(response.data);
                    setLoading(false);
                })
                .catch(err => {
                    console.error('Error fetching products details:', err);
                    setError('Failed to load products details');
                    setLoading(false);
                });
        });
    }, [teamId, productsId]);

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    return (
        <Container maxWidth="md">
            <Paper style={{ padding: 16, marginTop: 16 }}>
                {products ? (
                    <>
                        <Typography variant="h4">{products.name}</Typography>
                        <Typography variant="body1" style={{ marginTop: 8 }}>
                            Contact Info: {products.contactInfo}
                        </Typography>
                        {/* Add additional products details here */}
                    </>
                ) : (
                    <Typography variant="body1">Products not found.</Typography>
                )}
            </Paper>
        </Container>
    );
};

export default ProductsDetails;
