import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTeams } from '../../context/TeamsProvider';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

const favoriteTeams = [
  { id: 'fav1', name: 'My Favorite Team' },
  { id: 'fav2', name: 'Another Favorite' },
];

const TeamSelection = () => {
  const navigate = useNavigate();
  const {
    teams,
    loading,
    error,
    currentTeam,
    setCurrentTeam,
    recentTeams,
  } = useTeams();

  const [anchorEl, setAnchorEl] = useState(null);

  // For the "More..." dialog
  const [dialogOpen, setDialogOpen] = useState(false);

  // For the Tabs inside the dialog
  const [activeTab, setActiveTab] = useState(0);

  const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);


  useEffect(() => {
    if (!loading && !error && teams.length === 0) {
      navigate('/team/add');
    }

    
    if (!loading && !error && teams.length > 0 && !currentTeam) {
      setDialogOpen(true);
    }
  }, [loading, error, teams, navigate]);


  // Select a team from the inline menu (non-dialog)
  const handleSelectTeam = (team) => {
    setCurrentTeam({ id: team.team_id, name: team.name });
    navigate(`/`); // Change if you want a different route
    handleMenuClose();
  };

  // Select a recent team from the inline menu
  const handleSelectRecentTeam = (rt) => {
    setCurrentTeam(rt);
    navigate(`/`);
    handleMenuClose();
  };

  // "More..." => open the dialog
  const handleMore = () => {
    setDialogOpen(true);
    handleMenuClose();
  };
  const closeDialog = () => setDialogOpen(false);

  // Tabs in the "More..." dialog
  const handleTabChange = (_event, newValue) => setActiveTab(newValue);

  // Selecting a team from inside the dialog tabs
  const handleDialogSelectTeam = (t) => {
    // Could come from "recentTeams" or "favoriteTeams" or "teams"
    let teamId, teamName;
    if (t.team_id && t.name) {
      // from the `teams` array shape
      teamId = t.team_id;
      teamName = t.name;
    } else if (t.id && t.name) {
      // from "recentTeams"/"favoriteTeams"
      teamId = t.id;
      teamName = t.name;
    }

    if (teamId && teamName) {
      setCurrentTeam({ id: teamId, name: teamName });
      navigate(`/`);
      closeDialog();
    }
  };

  // --- Build a combined list of up to 6 items (recent first, then some from teams).
  const maxItems = 6;

  // Convert recentTeams to consistent shape
  const recentList = recentTeams.map((rt) => ({
    id: rt.id,
    name: rt.name,
    isRecent: true,
  }));

  // Filter out any from teams that appear in recent
  const uniqueTeams = teams.filter(
    (t) => !recentList.some((r) => r.id === String(t.team_id))
  );

  // Convert them to consistent shape
  const fullList = uniqueTeams.map((team) => ({
    id: String(team.team_id),
    name: team.name,
    isRecent: false,
  }));

  // Combine, ensuring up to 6 total
  const needed = maxItems - recentList.length;
  const filler = needed > 0 ? fullList.slice(0, needed) : [];
  const combinedList = [...recentList, ...filler];

  return (
    <>
      {/* Current team button (inline) */}
      <Button onClick={handleMenuClick}>
        {currentTeam
          ? currentTeam.name
          : loading
          ? 'Loading...'
          : 'No Team'}
      </Button>

      {/* Inline menu */}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        {error && (
          <MenuItem disabled>Error: {error}</MenuItem>
        )}
        {loading && (
          <MenuItem disabled>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CircularProgress size="1rem" sx={{ mr: 1 }} />
              Loading...
            </Box>
          </MenuItem>
        )}

        {/* Single block: up to 6 items, combining recent + teams */}
        {combinedList.length > 0 &&
          combinedList.map((item) => (
            <MenuItem
              key={item.id}
              onClick={() => {
                if (item.isRecent) {
                  handleSelectRecentTeam({
                    id: item.id,
                    name: item.name,
                  });
                } else {
                  handleSelectTeam({
                    team_id: item.id,
                    name: item.name,
                  });
                }
              }}
            >
              {item.name}
            </MenuItem>
          ))}

        {/* If we are not loading or erroring, but no teams found at all */}
        {!loading && !error && teams.length === 0 && (
          <MenuItem disabled>No teams found</MenuItem>
        )}

        {/* Divider if you want one */}
        {recentTeams.length > 0 && <Divider />}

        {/* "More…" tabbed dialog */}
        <MenuItem onClick={handleMore}>More…</MenuItem>

        {/* "Add team" example */}
        <MenuItem
          onClick={() => {
            navigate('/team/add');
            handleMenuClose();
          }}
        >
          Make Team
        </MenuItem>
      </Menu>

      {/* "More..." Dialog with Tabs: "Recent" / "All" */}
      <Dialog open={dialogOpen} onClose={closeDialog} fullWidth maxWidth="md">
        {/* 
          Dialog Title with "Make New Team" button on the right 
          using a Box flex layout
        */}
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">Teams</Typography>
            <Button
              variant="contained"
              onClick={() => {
                navigate('/team/add');
                // optionally close the dialog if you want:
                 closeDialog();
              }}
            >
              Make New Team
            </Button>
          </Box>
        </DialogTitle>

        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Recent" />
          {/* If you want "Fav," add <Tab label="Fav" /> here */}
          <Tab label="All" />
        </Tabs>

        <DialogContent dividers sx={{ minHeight: 300 }}>
          {/* Tab 0: Recent Teams */}
          {activeTab === 0 && (
            <List subheader={<ListSubheader>Recent Teams</ListSubheader>}>
              {recentTeams.length === 0 ? (
                <ListItem>
                  <ListItemText primary="No recent teams." />
                </ListItem>
              ) : (
                recentTeams.map((rt) => (
                  <ListItem disablePadding key={rt.id}>
                    <ListItemButton onClick={() => handleDialogSelectTeam(rt)}>
                      <ListItemText primary={rt.name} />
                    </ListItemButton>
                  </ListItem>
                ))
              )}
            </List>
          )}

          {/* Tab 1: All Teams (if no Fav tab) */}
          {activeTab === 1 && (
            <List subheader={<ListSubheader>All Teams</ListSubheader>}>
              {loading && (
                <ListItem>
                  <CircularProgress size="1rem" sx={{ mr: 1 }} />
                  <ListItemText primary="Loading teams..." />
                </ListItem>
              )}
              {error && (
                <ListItem>
                  <ListItemText primary={`Error: ${error}`} />
                </ListItem>
              )}
              {!loading && !error && teams.length === 0 && (
                <ListItem>
                  <ListItemText primary="No teams found" />
                </ListItem>
              )}
              {!loading &&
                !error &&
                teams.map((t) => (
                  <ListItem disablePadding key={t.team_id}>
                    <ListItemButton onClick={() => handleDialogSelectTeam(t)}>
                      <ListItemText primary={t.name} />
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={closeDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TeamSelection;
