import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import Loader from '../../components/other/Loader';
import { baseAPIURL } from '../../store/api/Access';
import {
  Container,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';

import { useTeams } from '../../context/TeamsProvider';

const TeamProjects = () => {
  const { currentTeam } = useTeams();
  const teamId = currentTeam?.id;

  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // For search & filter
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  // Fetch projects
  useEffect(() => {
    if (!teamId) return;

    setLoading(true);

    firebase.auth().currentUser
      .getIdToken()
      .then(idToken => {
        const config = {
          method: 'get',
          url: `${baseAPIURL}/teams/${teamId}/projects`,
          headers: { Authorization: idToken },
        };

        return axios.request(config);
      })
      .then(response => {
        setProjects(response.data);
        setLoading(false);
      })
      .catch(err => {
        console.error('Error fetching projects:', err);
        setError('Failed to load projects');
        setLoading(false);
      });
  }, [teamId]);

  // Shorten descriptions
  const shortenDescription = (text, maxLength = 80) => {
    if (!text) return '';
    return text.length > maxLength
      ? text.slice(0, maxLength - 3) + '...'
      : text;
  };

  // Filtered + searched projects
  const filteredProjects = projects.filter(project => {
    const matchSearch =
      project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (project.description &&
        project.description.toLowerCase().includes(searchTerm.toLowerCase()));

    const matchStatus = statusFilter
      ? project.status === statusFilter
      : true;

    return matchSearch && matchStatus;
  });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Typography variant="body1" color="error">
        {error}
      </Typography>
    );
  }

  return (
    <Container maxWidth="md">
      <Paper style={{ padding: 16, marginTop: 16 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
          <Typography variant="h4">Team Projects</Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Button
            component={NavLink}
            to={`/new-project`}
            variant="contained"
            color="primary"
          >
            Add New Project
          </Button>
        </Box>

        {/* Search & Filter */}
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            marginBottom: 2,
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
          <FormControl variant="outlined" size="small">
            <InputLabel id="project-status-filter-label">Status</InputLabel>
            <Select
              labelId="project-status-filter-label"
              value={statusFilter}
              onChange={e => setStatusFilter(e.target.value)}
              label="Status"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="planning">Planning</MenuItem>
              <MenuItem value="in_progress">In Progress</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
              <MenuItem value="on_hold">On Hold</MenuItem>
              <MenuItem value="cancelled">Cancelled</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {filteredProjects.length > 0 ? (
          <List>
            {filteredProjects.map(project => {
              const shortDesc = shortenDescription(project.description, 80);
              // Format dates if desired
              const startDate = project.start_date ? project.start_date : 'N/A';
              const endDate = project.end_date ? project.end_date : 'N/A';
              const status = project.status || 'N/A';

              return (
                <ListItem
                  key={project.project_id}
                  button
                  component={Link}
                  to={`/projects/${project.project_id}`}
                >
                  <ListItemText
                    primary={`${project.name} (Status: ${status})`}
                    secondary={
                      <>
                        {shortDesc && <div>{shortDesc}</div>}
                        <div>
                          Start: {startDate} | End: {endDate}
                        </div>
                        {project.budget && <div>Budget: {project.budget}</div>}
                      </>
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        ) : (
          <Typography variant="body1">
            No projects found for this team.
          </Typography>
        )}
      </Paper>
    </Container>
  );
};

export default TeamProjects;
