import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import Loader from '../../components/other/Loader';
import { baseAPIURL } from '../../store/api/Access';
import { Container, Typography, Paper, Button, Box, CircularProgress, Alert, TextField } from '@mui/material';


import { useTeams } from '../../context/TeamsProvider';

const UploadAttachment = () => {
    const { currentTeam } = useTeams();
   // const { teamId } = useParams();
    const teamId = currentTeam?.id; 

    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(null);
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            setError('Please select a file to upload.');
            return;
        }
        setLoading(true);
        setError('');

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('description', description);

        try {
            const idToken = await firebase.auth().currentUser.getIdToken();
            await axios.post(`${baseAPIURL}/teams/${teamId}/attachments/upload`, formData, {
                headers: {
                    'Authorization': idToken,
                    'Content-Type': 'multipart/form-data'
                }
            });
            navigate(`/attachments`);
        } catch (err) {
            console.error('Error uploading file:', err);
            setError('Failed to upload file.');
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="sm">
            <Paper style={{ padding: 16, marginTop: 16 }}>
                <Typography variant="h6">Upload Attachment</Typography>
                {error && <Alert severity="error" style={{ marginBottom: 16 }}>{error}</Alert>}
                <Box marginY={2}>
                    <input type="file" onChange={handleFileChange} />
                </Box>
                <Box marginY={2}>
                    <TextField
                        fullWidth
                        label="Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        multiline
                        rows={2}
                    />
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUpload}
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={24} /> : 'Upload'}
                </Button>
            </Paper>
            {loading && <Loader />}
        </Container>
    );
};

export default UploadAttachment;
