import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import Loader from '../../components/other/Loader';
import { baseAPIURL } from '../../store/api/Access';
import { Container, Typography, Paper, List, ListItem, ListItemText, Alert, Button, Box } from '@mui/material';
import { NavLink  } from 'react-router-dom'


import { useTeams } from '../../context/TeamsProvider';


const TeamAttachments = () => {
    const { currentTeam } = useTeams();

    const teamId = currentTeam?.id; 
    const navigate = useNavigate();
    const [attachments, setAttachments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        setLoading(true);

        firebase.auth().currentUser.getIdToken().then(function(idToken) {
            let config = {
                method: 'get',
                url: `${baseAPIURL}/teams/${teamId}/attachments`,
                headers: { 'Authorization': idToken }
            };

            axios.request(config)
                .then(response => {
                    setAttachments(response.data);
                    setLoading(false);
                })
                .catch(err => {
                    console.error('Error fetching attachments:', err);
                    setError('Failed to load attachments');
                    setLoading(false);
                });
        });
    }, [teamId]);

    const handleUploadPage = () => {
        navigate(`/upload`);
    };

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    return (
        <Container maxWidth="md">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h4">Team Attachments</Typography>
                <Button variant="contained" color="primary" onClick={handleUploadPage}>
                    Upload New Attachment
                </Button>
            </Box>
            <Paper style={{ padding: 16 }}>
                {attachments.length > 0 ? (
                    <List>
                        {/* List of attachments */}
                    </List>
                ) : (
                    <Typography variant="body1">No attachments found for this team.</Typography>
                )}
            </Paper>
        </Container>
    );
};

export default TeamAttachments;
