import React from 'react';

import { BrowserRouter, Route, Routes  } from 'react-router-dom'
import Box from '@mui/material/Box';

import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import Page404 from './views/Page404' 
import Logout from './views/auth/Logout' 

import Home from './views/Home'
import Account from './views/Account'
import UnderConstruction from './views/UnderConstruction'




import TeamNew from './views/Team/New'
import TeamHome from './views/TeamHome'



import TeamSettingsView from './views/Settings/View'
import TeamSettingsTeam from './views/Settings/Tabs/Team'
import TeamSettingsUsers from './views/Settings/Tabs/Users'
import TeamSettingsOther from './views/Settings/Tabs/Other'





import TeamProjectsList from './views/Projects/List'
import TeamProjectsNew from './views/Projects/New'
import TeamProjectsItem from './views/Projects/View'

import TeamDocumentsItem from './views/Documents/Item'


import TeamProductsList from './views/Products/List'
import TeamProductsNew from './views/Products/New'
import TeamProductsItem from './views/Products/Item'


import TeamAttachmentsList from './views/Attachments/List'
import TeamAttachmentsUpload from './views/Attachments/Upload'
import TeamAttachmentsItem from './views/Attachments/Item'

import TeamCustomersList from './views/Customers/List'
import TeamCustomersNew from './views/Customers/New'
import TeamCustomersItem from './views/Customers/Item'

import TeamSuppliersList from './views/Suppliers/List'
import TeamSuppliersNew from './views/Suppliers/New'
import TeamSuppliersItem from './views/Suppliers/Item'


import TeamProjectsTasksList from './views/Projects/Tabs/Tasks/List';
import TeamProjectsTasksView from './views/Projects/Tabs/Tasks/View';

import TeamProjectsOverview from './views/Projects/Tabs/Overview';
import TeamProjectsDocuments from './views/Projects/Tabs/Documents';
import TeamProjectsAttachments from './views/Projects/Tabs/Attachments';
import TeamProjectsCustomer from './views/Projects/Tabs/Customer';
import TeamProjectsBom from './views/Projects/Tabs/Bom';
import TeamProjectsAccounting from './views/Projects/Tabs/Accounting';


import InventoryList from './views/Inventory/List';
import InventoryNew from './views/Inventory/New';



function App() {
  return (
    <BrowserRouter>
      <Box className="App" sx={{ "display": 'flex' }}>
          <Header /> 

          <Box className="MainView" sx={{flexGrow: 1}}>
            <Routes >
              <Route path='/' element={<Home/>} />
              <Route path='/logout' element={<Logout/>} />
              

              <Route path='/team'>
                <Route index element={<Home/>} />
                <Route path='add' element={<TeamNew/>} />

              

                {/*<Route path=':teamId'>
                  
                </Route>*/}
              
              </Route>  
                  
                

                  <Route index element={<TeamHome/>} />


                  <Route path='settings' element={<TeamSettingsView/>} >
                    <Route index element={<TeamSettingsTeam />} />
                    <Route path="team" element={<TeamSettingsUsers/>} />
                    <Route path="other" element={<TeamSettingsOther/>} />
                    <Route path='*'  element={<Page404/>}  status={404}/>

                  </Route>

                  <Route path='new-project' element={<TeamProjectsNew/>} />
                  <Route path='projects' >
                    <Route index element={<TeamProjectsList/>} />
                    <Route path=':projectId' element={<TeamProjectsItem/>} >
                      <Route index element={<TeamProjectsOverview />} />


                      <Route path="tasks" >
                        <Route index element={<TeamProjectsTasksList />} />
                        <Route path=':taskId' element={<TeamProjectsTasksView/>} />
                      </Route>

                      <Route path="documents" element={<TeamProjectsDocuments />} />
                      <Route path="attachments" element={<TeamProjectsAttachments />} />
                      <Route path="accounting" element={<TeamProjectsAccounting />} />
                      <Route path="customer" element={<TeamProjectsCustomer />} />
                      <Route path="bom" element={<TeamProjectsBom />} />
                      
                    </Route>

                  </Route>




                  <Route path='documents'>
                    <Route index element={<TeamDocumentsItem/>} />
                    <Route path=':documentId' element={<TeamDocumentsItem/>} />
                  </Route>







                  <Route path='inventory'>

                    {/* Inventory Routes */}
                    <Route index element={<InventoryList />} /> {/* List all items */}
                    <Route path="new" element={<InventoryNew />} /> {/* Create new item */}
                    <Route path=":inventoryId" element={<UnderConstruction />} /> {/* View single item */}
                    <Route path=":inventoryId/edit" element={<UnderConstruction />} /> {/* Edit an item */}

                    {/* Inventory Categories */}
                    <Route path="categories" element={<UnderConstruction />} /> {/* List categories */}
                    <Route path="categories/new" element={<UnderConstruction />} /> {/* Create category */}
                    <Route path="categories/:categoryId" element={<UnderConstruction />} /> {/* View category */}
                    <Route path="categories/:categoryId/edit" element={<UnderConstruction />} /> {/* Edit category */}

                    {/* Inventory Locations */}
                    <Route path="locations" element={<UnderConstruction />} /> {/* List locations */}
                    <Route path="locations/new" element={<UnderConstruction />} /> {/* Create location */}
                    <Route path="locations/:locationId" element={<UnderConstruction />} /> {/* View location */}
                    <Route path="locations/:locationId/edit" element={<UnderConstruction />} /> {/* Edit location */}
                  
                  </Route>








                  <Route path='new-products' element={<TeamProductsNew/>} />
                  <Route path='products'>
                    <Route index element={<TeamProductsList/>} />
                    <Route path=':productsId' element={<TeamProductsItem/>} />
                  </Route>





                  <Route path='upload' element={<TeamAttachmentsUpload/>} />
                  <Route path='attachments'>
                    <Route index element={<TeamAttachmentsList/>} />
                    <Route path=':attachmentsId' element={<TeamAttachmentsItem/>} />
                  </Route>



                  <Route path='new-customers' element={<TeamCustomersNew/>} />
                  <Route path='customers'>
                    <Route index element={<TeamCustomersList/>} />
                    <Route path=':customerId' element={<TeamCustomersItem/>} />
                  </Route>




                  <Route path='new-suppliers' element={<TeamSuppliersNew/>} />
                  <Route path='suppliers'>
                    <Route index element={<TeamSuppliersList/>} />
                    <Route path=':suppliersId' element={<TeamSuppliersItem/>} />
                  </Route>








                  
                  <Route path='suppliers' element={<UnderConstruction/>} />
                  <Route path='products' element={<UnderConstruction/>} />
                  <Route path='accounting' element={<UnderConstruction/>} />

              <Route path='/account' element={<Account/>} />
                
              <Route path='*'  element={<Page404/>}  status={404}/>
            </Routes >
          </Box>
          
          <Footer />
      </Box>
    </BrowserRouter>
  );
}

export default App;
