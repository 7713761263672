import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';

import Logo from '../../assets/Logo.png';
import TeamSelection from './TeamSelection';
import Sidebar from './Sidebar'; // if you have a side drawer

const drawerWidth = 240;
const MaxViewWidth = 500;

const Header = () => {
  const { pathname } = useLocation();

  // If you want to show/hide a drawer on /team/:id
  const pathSegments = pathname.split('/');
  const maybeTeamId = pathSegments[2] || null;
  const isTeamRoute = pathname !== '/team/add' && pathname !== '/account' ;

  const [isWideView, setIsWideView] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Handle resizing for wide screens vs. smaller
  useEffect(() => {
    const handleResize = () => {
      setIsWideView(window.innerWidth < drawerWidth + MaxViewWidth);
      setDrawerOpen(false);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{background:"#fff !important", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          {/* Optional: Hamburger icon if we are on a /team route and wide view */}
          {isTeamRoute && isWideView && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
          )}

          {/* Logo (links to "/") */}
          <Box
            component={NavLink}
            to="/"
            sx={{ display: 'flex', alignItems: 'center', mr: 2 }}
          >
            <img src={Logo} alt="logo" className="HeaderLogo" />
          </Box>

          {/* Team selection dropdown button */}
          <TeamSelection />

          {/* Fill remaining space */}
          <Box sx={{ flexGrow: 1 }} />

          {/* User/Account icon on the right side */}
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            component={NavLink}
            to="/account"
          >
            <PersonIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Optional: side drawer if on a /team route */}
      {isTeamRoute && (drawerOpen || !isWideView) && (
        <Sidebar
          open={drawerOpen}
          isWideView={isWideView}
          teamId={maybeTeamId}
          pathname={pathname}
          handleDrawerClose={() => setDrawerOpen(false)}
        />
      )}
    </>
  );
};

export default Header;
