import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useCallback,
  } from 'react';
  import axios from 'axios';
  import firebase from 'firebase/compat/app';
  import { baseAPIURL } from '../store/api/Access';
  import cookieStorage from "../functions/cookieStorage";

  /** 
   * ========== Session Storage Helpers ==========
   * You can store the current team & recent teams here.
   */
  function setCurrentTeamInSession(teamObj) {
    if (!teamObj?.id) return;
    cookieStorage.setItem('currentTeam', JSON.stringify(teamObj));
  
    // Update recent teams
    let recent = [];
    try {
      recent = JSON.parse(cookieStorage.getItem('recentTeams')) || [];
    } catch (e) {
      // ignore parse error
    }
    // Move or insert at front
    const existingIndex = recent.findIndex((t) => t.id === teamObj.id);
    if (existingIndex >= 0) {
      recent.splice(existingIndex, 1);
    }
    recent.unshift(teamObj);
  
    // Keep the list small
    recent = recent.slice(0, 5);
  
    cookieStorage.setItem('recentTeams', JSON.stringify(recent));
  }
  
  function getCurrentTeamFromSession() {
    try {
      return JSON.parse(cookieStorage.getItem('currentTeam')) || null;
    } catch (e) {
      return null;
    }
  }
  
  function getRecentTeamsFromSession() {
    try {
      return JSON.parse(cookieStorage.getItem('recentTeams')) || [];
    } catch (e) {
      return [];
    }
  }
  
  /**
   * ========== Create the Context ==========
   */
  const TeamsContext = createContext(null);
  
  /**
   * ========== Export a Hook for easy usage ==========
   * This allows any component to do: const { teams, ... } = useTeams();
   */
  export function useTeams() {
    return useContext(TeamsContext);
  }
  
  /**
   * ========== The Provider Component ==========
   * Wrap your entire app (or top-level routes) with <TeamsProvider> so that
   * child components can access the teams data and currentTeam states.
   */
  export function TeamsProvider({ children }) {
    // Holds the entire list of teams from the API
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    // Current team & recent teams from cookieStorage
    const [currentTeam, setCurrentTeamState] = useState(
      getCurrentTeamFromSession()
    );
    const [recentTeams, setRecentTeams] = useState(getRecentTeamsFromSession());
  
    /**
     * ========== 1) Function to Fetch Teams ==========
     */
    const fetchTeams = useCallback(() => {
      const user = firebase.auth().currentUser;
      if (!user) {
        console.warn('No currentUser found; cannot fetch teams.');
        return;
      }
  
      setLoading(true);
      setError(null);
  
      user
        .getIdToken()
        .then((idToken) => {
          return axios.get(`${baseAPIURL}/teams`, {
            headers: {
              Authorization: idToken,
              'Content-Type': 'application/json',
            },
          });
        })
        .then((response) => {
          // Assumes response.data is an array of teams
          setTeams(response.data);
        })
        .catch((err) => {
          console.error('Error fetching teams:', err);
          setError(err.message || 'Could not load teams');
        })
        .finally(() => {
          setLoading(false);
        });
    }, []);
  
    /**
     * ========== 2) On Mount, Fetch Teams ==========
     */
    useEffect(() => {
      fetchTeams();
    }, [fetchTeams]);
  
    /**
     * ========== 3) Setting the Current Team ==========
     * This also updates cookieStorage so it persists per tab.
     */
    const setCurrentTeam = (teamObj) => {
      if (!teamObj) return; // or handle as needed
      setCurrentTeamInSession(teamObj);
      setCurrentTeamState(teamObj);
      // Refresh local copy of recent teams from session
      setRecentTeams(getRecentTeamsFromSession());
    };
  
    /**
     * ========== Provide all these values to context ==========
     */
    const value = {
      teams,
      loading,
      error,
      currentTeam,
      setCurrentTeam,
      recentTeams,
      fetchTeams, // if components want to manually re-fetch
    };
  
    return (
      <TeamsContext.Provider value={value}>
        {children}
      </TeamsContext.Provider>
    );
  }
  