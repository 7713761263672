import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import { Container, TextField, Button, Typography, FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import { baseAPIURL } from '../../../store/api/Access'; // Adjust the import path
import { useTeams } from '../../../context/TeamsProvider';

const TeamSettings = () => {
    const { currentTeam } = useTeams();
    const teamId = currentTeam?.id; ;
    const navigate = useNavigate();
    const [teamDetails, setTeamDetails] = useState({
        name: '',
        description: '',
        status: '',
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        setLoading(true);
        // Replace with your actual fetch logic
        firebase.auth().currentUser.getIdToken().then(function(idToken) {
            axios.get(`${baseAPIURL}/teams/${teamId}`, {
                headers: { 'Authorization': idToken },
            })
            .then((response) => {
                setTeamDetails(response.data);
                setLoading(false);
            })
            .catch((err) => {
                console.error('Error fetching team:', err);
                setError('Failed to load team details.');
                setLoading(false);
            });
        });
    }, [teamId]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setTeamDetails({ ...teamDetails, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        // Replace with your actual update logic
        firebase.auth().currentUser.getIdToken().then(function(idToken) {
            axios.put(`${baseAPIURL}/teams/${teamId}`, teamDetails, {
                headers: { 'Authorization': idToken },
            })
            .then(() => {
                setLoading(false);
                //navigate(-1); // Or navigate to a specific URL
            })
            .catch((err) => {
                console.error('Error updating team:', err);
                setError('Failed to update team details.');
                setLoading(false);
            });
        });
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <Container component="main" maxWidth="xs">
            <Typography component="h1" variant="h5">Edit Team Settings</Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Team Name"
                    name="name"
                    autoComplete="name"
                    autoFocus
                    value={teamDetails.name}
                    onChange={handleChange}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="description"
                    label="Description"
                    type="text"
                    id="description"
                    autoComplete="current-description"
                    value={teamDetails.description}
                    onChange={handleChange}
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel>Status</InputLabel>
                    <Select
                        labelId="status-label"
                        id="status"
                        name="status"
                        value={teamDetails.status}
                        label="Status"
                        onChange={handleChange}
                    >
                        <MenuItem value="active">Active</MenuItem>
                        <MenuItem value="inactive">Inactive</MenuItem>
                        <MenuItem value="archived">Archived</MenuItem>
                    </Select>
                </FormControl>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Save Changes
                </Button>
                {error && <Typography color="error">{error}</Typography>}
            </Box>
        </Container>
    );
};

export default TeamSettings;
